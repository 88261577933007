<script lang="ts">

	import type buttonVariant from "./StdButton";
	import StdButtonNone from "./StdButtonNone.svelte";
	import StdButtonPinged from "./StdButtonPinged.svelte";
	import StdButtonDisabled from "./StdButtonDisabled.svelte";

	export let onClicked: () => void = undefined;
	export let variant: buttonVariant = "blue";
	export let pingable: boolean = false;
	export let disabled: boolean = false;
	export let href: string = undefined;
	export let download: string = undefined;
	if (download && !href) throw new Error("If button is download button, property 'href' must be assigned!");
	if (!download && !href) {
		if (!onClicked) {
			throw new Error("If the button is not a download button, it must have a onClicked property");
		}
	}
	let pinged = false;
	let hide = false;

	const click = () => {
		if (pingable) {
			pinged = true;
			setTimeout(() => {
				hide = true;
			}, 1000);
		}
		if (onClicked)
			onClicked();
	};
</script>

{#if disabled}
	<StdButtonDisabled>
		<slot></slot>
	</StdButtonDisabled>
{:else}
	{#if (!pingable && !hide)}
		<StdButtonNone href={href} download={download} onClicked={click} variant={variant}>
			<slot></slot>
		</StdButtonNone>
	{/if}
	{#if (pingable && !hide)}
		{#if pinged}
			<StdButtonPinged>
				<StdButtonNone href={href} download={download} onClicked={click} variant={variant}>
					<slot></slot>
				</StdButtonNone>
			</StdButtonPinged>
		{/if}
		{#if !pinged}
			<StdButtonNone href={href} download={download} onClicked={click} variant={variant}>
				<slot></slot>
			</StdButtonNone>
		{/if}
	{/if}
{/if}