<script lang="ts">
	import type buttonVariant from "./StdButton";

	export let variant: buttonVariant = "blue";
	export let onClicked: () => void;
	export let href: string = undefined;
	export let download: string = undefined;
	if (download && !href) throw new Error("If button is download button, property 'href' must be assigned!");

</script>

{#if variant === "blue"}
	{#if href}
		<a href={href} download={download} on:click={onClicked}>
			<div class="
				text-center bg-blue-500
				border-solid rounded text-white
				cursor-pointer select-none
				hover:bg-blue-700 p-1 pr-3 pl-3">
				<slot></slot>
			</div>
		</a>
	{:else}
		<div on:click={onClicked}
				 class="
				text-center bg-blue-500
				border-solid rounded text-white
				cursor-pointer select-none
				hover:bg-blue-700 p-1 pr-3 pl-3">
			<slot></slot>
		</div>
	{/if}
{/if}

{#if variant === "green"}
	{#if href}
		<a href={href} download={download} on:click={onClicked}>
			<div class="
				text-center bg-green-500
				border-solid rounded text-white
				cursor-pointer select-none
				hover:bg-green-700 p-1 pr-3 pl-3">
				<slot></slot>
			</div>
		</a>
	{:else }
		<div on:click={onClicked}
				 class="
				text-center bg-green-500
				border-solid rounded text-white
				cursor-pointer select-none
				hover:bg-green-700 p-1 pr-3 pl-3">
			<slot></slot>
		</div>
	{/if}
{/if}

{#if variant === "white"}
	{#if href}
		<a href={href} download={download} on:click={onClicked}>
			<div class="
        text-center bg-white
        font-bold
        border-solid border-blue-500 border-2
        border-solid rounded text-blue-500
        cursor-pointer select-none
        hover:bg-blue-100 p-1 pr-3 pl-3">
				<slot></slot>
			</div>
		</a>
	{:else }
		<div on:click={onClicked}
				 class="
        text-center bg-white
        font-bold
        border-solid border-blue-500 border-2
        border-solid rounded text-blue-500
        cursor-pointer select-none
        hover:bg-blue-100 p-1 pr-3 pl-3">
			<slot></slot>
		</div>
	{/if}
{/if}

{#if variant === "disabled"}
	<div on:click={onClicked}
			 class="
        text-center bg-gray-500/80
        font-bold rounded text-gray-200
        select-none
        p-1 pr-3 pl-3">
		<slot></slot>
	</div>
{/if}