import MBSpecs from "./MBSpecs";
/**
 * The connection to the micro:bit.
 */
export class MicrobitBluetooth {
    /**
     * Constructs a bluetooth connection object. Should not be called directly.
     * Use MicrobitBluetooth.createConnection instead.
     *
     *    The microbit device to connect to.
     *    Callback to be called when the connection is established.
     * @param {BluetoothRemoteGATTServer} gattServer
     *      The gattServer, that has been attached to this micro:bit.
     * @param {number} microbitVersion
     *      The version of micro:bit.
     * @param {boolean => void} onDisconnect
     *      Fired when the micro:bit disconnects.
     */
    constructor(gattServer, microbitVersion, onDisconnect) {
        this.gattServer = gattServer;
        this.microbitVersion = microbitVersion;
        this.onDisconnect = onDisconnect;
        this.device = gattServer.device;
        this.device.addEventListener("gattserverdisconnected", this.disconnectListener.bind(this));
    }
    /**
     * Opens a requestDevice prompt on the browser, searching for a micro:bit with the given name.
     * @param {string} name
     *      The name of the micro:bit.
     * @param {Error => void} onRequestFailed
     *      Fired if the request failed.
     */
    static async requestDevice(name, onRequestFailed) {
        return new Promise(async (resolve, reject) => {
            try {
                const device = await navigator.bluetooth.requestDevice({
                    filters: [{ namePrefix: `BBC micro:bit [${name}]` }],
                    optionalServices: [
                        MBSpecs.Services.UART_SERVICE,
                        MBSpecs.Services.ACCEL_SERVICE,
                        MBSpecs.Services.DEVICE_INFO_SERVICE,
                        MBSpecs.Services.LED_SERVICE,
                        MBSpecs.Services.IO_SERVICE,
                        MBSpecs.Services.BUTTON_SERVICE
                    ]
                });
                resolve(device);
            }
            catch (e) {
                if (onRequestFailed) {
                    onRequestFailed(e);
                }
                reject(e);
            }
        });
    }
    /**
     * Attempts to connect to the micro:bit device.
     *
     * @param {BluetoothDevice} microbitDevice
     *      The microbit device to connect to.
     * @param {BluetoothRemoteGATTServer => void} onConnect
     *      Fired when a successful connection is made.
     * @param {boolean => void} onDisconnect
     *      Fired when the device disconnects.
     * @param {Error => void} onConnectFailed
     *      Called when the connection failed.
     */
    static async createConnection(microbitDevice, onConnect, onDisconnect, onConnectFailed) {
        try {
            const gattServer = await microbitDevice.gatt.connect();
            const microbitVersion = await MBSpecs.Utility.getModelNumber(gattServer);
            // Create the connection object.
            const connection = new MicrobitBluetooth(gattServer, microbitVersion, onDisconnect);
            // fire the connect event and return the connection object
            if (onConnect) {
                if (gattServer.connected) {
                    await onConnect(gattServer);
                }
            }
            return connection;
        }
        catch (e) {
            if (onConnectFailed) {
                await onConnectFailed(e);
            }
            return undefined;
        }
    }
    /**
     * Adds a listener for the 'gattserverdisconnected' event.
     * @param {Event => void} callback The function to execute.
     */
    listenForDisconnect(callback) {
        return this.device.addEventListener("gattserverdisconnected", callback);
    }
    /**
     * Removes a listener for the 'gattserverdisconnected' event.
     * @param callback
     */
    removeDisconnectListener(callback) {
        return this.device.removeEventListener("gattserverdisconnected", callback);
    }
    /**
     * @returns {BluetoothDevice} The BluetoothDevice object of the micro:bit.
     */
    getDevice() {
        return this.device;
    }
    /**
     * @returns {Promise<BluetoothRemoteGATTService>} The UART service of the micro:bit.
     */
    async getUARTService() {
        try {
            return await this.gattServer.getPrimaryService(MBSpecs.Services.UART_SERVICE);
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * @returns {Promise<BluetoothRemoteGATTService>} The accelerometer service of the micro:bit.
     */
    async getAccelerometerService() {
        try {
            return await this.gattServer.getPrimaryService(MBSpecs.Services.ACCEL_SERVICE);
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * @returns {Promise<BluetoothRemoteGATTService>} The button service of the micro:bit.
     */
    async getButtonService() {
        try {
            return await this.gattServer.getPrimaryService(MBSpecs.Services.BUTTON_SERVICE);
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * @returns {Promise<BluetoothRemoteGATTService>} The device information service of the micro:bit.
     */
    async getDeviceInfoService() {
        try {
            return await this.gattServer.getPrimaryService(MBSpecs.Services.DEVICE_INFO_SERVICE);
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * @returns {Promise<BluetoothRemoteGATTService>} The LED service of the micro:bit.
     */
    async getLEDService() {
        try {
            return await this.gattServer.getPrimaryService(MBSpecs.Services.LED_SERVICE);
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * @returns {Promise<BluetoothRemoteGATTService>} The IO service of the micro:bit.
     */
    async getIOService() {
        try {
            return await this.gattServer.getPrimaryService(MBSpecs.Services.IO_SERVICE);
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * Whether the connection is currently established.
     * @return {boolean} True if the connection is established, false otherwise.
     */
    isConnected() {
        return this.gattServer.connected;
    }
    /**
     * @return {BluetoothRemoteGATTServer} The GATT server of the micro:bit.
     */
    getGattServer() {
        if (!this.isConnected()) {
            throw new Error("MicrobitConnection: gatt server is not available until after connection is established");
        }
        return this.gattServer;
    }
    /**
     * @returns {number} The version of the micro:bit.
     */
    getVersion() {
        return this.microbitVersion;
    }
    /**
     * Disconnects from the micro:bit.
     */
    disconnect() {
        if (this.isConnected()) {
            this.device.removeEventListener("gattserverdisconnected", this.disconnectListener.bind(this));
            this.gattServer.disconnect();
            this.disconnectEventHandler(true);
        }
    }
    /**
     * Listen to the UART data transmission characteristic
     * @param {(string) => void} onDataReceived Callback to be called when data is received.
     */
    async listenToUART(onDataReceived) {
        const uartService = await this.getUARTService();
        const uartTXCharacteristic = await uartService.getCharacteristic(MBSpecs.Characteristics.UART_DATA_TX);
        await uartTXCharacteristic.startNotifications();
        uartTXCharacteristic.addEventListener("characteristicvaluechanged", (event) => {
            // Convert the data to a string.
            const receivedData = [];
            const target = event.target;
            for (let i = 0; i < target.value.byteLength; i += 1) {
                receivedData[i] = target.value.getUint8(i);
            }
            const receivedString = String.fromCharCode.apply(null, receivedData);
            onDataReceived(receivedString);
        });
    }
    /**
     * @param {MBSpecs.Button} buttonToListenFor
     *      The button to listen to.
     * @param {(MBSpecs.ButtonState, MBSpecs.Button) => void} onButtonChanged
     *      Button change callback.
     */
    async listenToButton(buttonToListenFor, onButtonChanged) {
        const buttonService = await this.getButtonService();
        // Select the correct characteristic to listen to.
        const UUID = buttonToListenFor === "A"
            ? MBSpecs.Characteristics.BUTTON_A
            : MBSpecs.Characteristics.BUTTON_B;
        const buttonCharacteristic = await buttonService.getCharacteristic(UUID);
        await buttonCharacteristic.startNotifications();
        buttonCharacteristic.addEventListener("characteristicvaluechanged", (event) => {
            const target = event.target;
            const stateId = target.value.getUint8(0);
            let state = MBSpecs.ButtonStates.Released;
            if (stateId === 1) {
                state = MBSpecs.ButtonStates.Pressed;
            }
            if (stateId === 2) {
                state = MBSpecs.ButtonStates.LongPressed;
            }
            onButtonChanged(state, buttonToListenFor);
        });
    }
    /**
     * @param {(number, number, number) => void} onAccelerometerChanged Callback to be executed when the accelerometer changes.
     */
    async listenToAccelerometer(onAccelerometerChanged) {
        const accelerometerService = await this.getAccelerometerService();
        const accelerometerCharacteristic = await accelerometerService.getCharacteristic(MBSpecs.Characteristics.ACCEL_DATA);
        await accelerometerCharacteristic.startNotifications();
        accelerometerCharacteristic.addEventListener("characteristicvaluechanged", (event) => {
            const target = event.target;
            const x = target.value.getInt16(0, true);
            const y = target.value.getInt16(2, true);
            const z = target.value.getInt16(4, true);
            onAccelerometerChanged(x, y, z);
        });
    }
    /**
     * Display the 5x5 matrix on the micro:bit.
     *
     * @param matrix The matrix to display.
     */
    async setLEDMatrix(matrix) {
        if (matrix.length !== 5 || matrix[0].length !== 5)
            throw new Error("Matrix must be 5x5");
        // To match overloads we must cast the matrix to a number[][]
        let numMatrix = matrix;
        if (typeof matrix[0][0] === "boolean")
            numMatrix = matrix.map((row) => row.map((value) => (value ? 1 : 0)));
        const ledService = await this.getLEDService();
        const ledCharacteristic = await ledService.getCharacteristic(MBSpecs.Characteristics.LED_MATRIX_STATE);
        // Create the dataview that will be sent through the bluetooth characteristic.
        const data = new Uint8Array(5);
        for (let i = 0; i < 5; i += 1)
            data[i] = MBSpecs.Utility.arrayToOctet(numMatrix[i]);
        const dataView = new DataView(data.buffer);
        await ledCharacteristic.writeValue(dataView);
    }
    /**
     * Reference for the disconnect listener. Makes it easier to remove it again later.
     * @param {Event} event The disconnect event
     * @private
     */
    disconnectListener(event) {
        this.disconnectEventHandler(false);
    }
    /**
     * Fires when the micro:bit disconnects.
     * @param {boolean} manualDisconnect
     *      Whether the disconnect was triggered by the user.
     */
    disconnectEventHandler(manualDisconnect) {
        if (this.disconnectHasFired)
            return;
        if (this.device === undefined)
            return;
        if (this.onDisconnect) {
            this.onDisconnect(manualDisconnect);
            this.disconnectHasFired = true;
        }
    }
}
export default MicrobitBluetooth;
