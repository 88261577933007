<script lang="ts">
	import MBSpecs from "../../script/microbit-interfacing/MBSpecs";
	import type MicrobitBluetooth from "../../script/microbit-interfacing/MicrobitBluetooth";
	import PatternMatrix from "../PatternMatrix.svelte";
	import { t } from "./../../i18n";
	import StdButton from "../stdbutton/StdButton.svelte";
	import { onDestroy, onMount } from "svelte";
	import EventEmittingMicrobitBluetooth from "../../script/microbit-interfacing/EventEmittingMicrobitBluetooth";

	// callbacks
	export let onBluetoothConnected: (
		device: MicrobitBluetooth,
		name: string
	) => void;
	export let onBluetoothError: (error: Error) => void;

	let showWrongPatternError = false;

	let patternMatrixState: boolean[] = undefined;
	if (!onBluetoothConnected)
		throw new Error("onBluetoothConnected callback is not defined");

	let name: string = "";

	const connectButtonClicked = async () => {
		if (name.length !== 5) {
			showWrongPatternError = true;
			return;
		}

		const btDevice = await EventEmittingMicrobitBluetooth.eventEmittingRequestDevice(
			name,
			onBluetoothError
		);
		if (btDevice) {
			const mb = await EventEmittingMicrobitBluetooth.createEventEmittingConnection(
				btDevice,
				void 0,
				undefined,
				onBluetoothError
			);
			onBluetoothConnected(mb, name);
		}
	};

	const setMatrix = (matrix: boolean[], index?: number) => {
		patternMatrixState = matrix;
		name = MBSpecs.Utility.patternToName(patternMatrixState);
		showWrongPatternError = false;
		updateLocalStorageMatrix(matrix);
	};

	/**
	 * Save matrix in localstorage and retrieve if present
	 */
	let matrix: boolean[] = [];
	let previousMatrix = localStorage.getItem("MicrobitIdentificationMatrix");
	if (previousMatrix !== null) {
		matrix = JSON.parse(previousMatrix);
		setMatrix(matrix);
	}

	function updateLocalStorageMatrix(matrix: boolean[]): void {
		localStorage.setItem(
			"MicrobitIdentificationMatrix",
			JSON.stringify(matrix)
		);
	}

	function connectOnEnterClick(event: KeyboardEvent): void {
		if (event.code !== "Enter") return;
		connectButtonClicked();
	}

	onMount(() => {
		document.addEventListener("keypress", connectOnEnterClick);
	});
	onDestroy(() => {
		document.removeEventListener("keypress", connectOnEnterClick);
	});
</script>

<div class="p-6">
	<!-- TITLE -->
	<div class="pb-0.5 mb-0.5 border-solid border-b-1">
		<p class="text-2xl font-bold text-red-500">
			{$t("popup.connectMB.bluetooth.heading")}
		</p>
	</div>

	<!-- CONTENT -->
	{#if showWrongPatternError}
		<div class="flex">
			<div class="flex-1 mr-2 text-center">
				<p class="text-2xl text-red-500">
					{$t("popup.connectMB.bluetooth.wrongPattern")}
				</p>
			</div>
		</div>
	{/if}
	<div class="flex mb-3 mt-3">
		<!-- USB -->
		<div class="flex-1 mr-2">
			<div class="text-blue-500 mb-1 text-xl text-bold">
				<p>{$t("popup.connectMB.bluetooth.subtitle")}</p>
			</div>
			<div class="flex">
				<div class="flex-1 mr-2">
					<table>
						<tr>
							<td> 1.</td>
							<td>
								{$t("popup.connectMB.bluetooth.step1")}
							</td>
						</tr>
						<tr>
							<td> 2.</td>
							<td>
								{$t("popup.connectMB.bluetooth.step2")}
							</td>
						</tr>
						<tr>
							<td> 3.</td>
							<td>
								{$t("popup.connectMB.bluetooth.step3")}
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="flex-1 mr-2 justify-center flex">
			<PatternMatrix
				{matrix}
				onSetElement={(elem, state, matrix) => setMatrix(matrix, elem)}
			/>
		</div>
	</div>
	<div class="flex-1 mr-0 text-center">
		<div class="flex-1 mr-2 justify-center flex">
			<StdButton onClicked={connectButtonClicked}>
				<p>{$t("popup.connectMB.bluetooth.connect")}</p>
			</StdButton>
		</div>
	</div>
</div>
