import MicrobitBluetooth from "./MicrobitBluetooth";
import MBSpecs from "./MBSpecs";
export class MicrobitBluetoothEvents {
}
MicrobitBluetoothEvents.microbitBluetoothDeviceFound = new Event("microbitBluetoothDeviceFound");
MicrobitBluetoothEvents.microbitBluetoothConnected = (microbitBluetooth) => {
    return new CustomEvent("microbitBluetoothConnected", {
        detail: { microbitBluetooth: microbitBluetooth }
    });
};
MicrobitBluetoothEvents.microbitBluetoothDisconnected = (microbitBluetooth) => {
    return new CustomEvent("microbitBluetoothDisconnected", {
        detail: { microbitBluetooth: microbitBluetooth }
    });
};
class EventEmittingMicrobitBluetooth extends MicrobitBluetooth {
    constructor(gattServer, microbitVersion, onDisconnect) {
        const onDC = (manual) => {
            document.dispatchEvent(MicrobitBluetoothEvents.microbitBluetoothDisconnected(this));
            if (onDisconnect) {
                onDisconnect(manual);
            }
        };
        super(gattServer, microbitVersion, onDC);
        document.dispatchEvent(MicrobitBluetoothEvents.microbitBluetoothConnected(this));
    }
    /**
     * Opens a requestDevice prompt on the browser, searching for a micro:bit with the given name.
     * @param {string} name
     *      The name of the micro:bit.
     * @param {Error => void} onRequestFailed
     *      Fired if the request failed.
     */
    static async eventEmittingRequestDevice(name, onRequestFailed) {
        return new Promise((resolve, reject) => {
            try {
                navigator.bluetooth
                    .requestDevice({
                    filters: [{ namePrefix: `BBC micro:bit [${name}]` }],
                    optionalServices: [
                        MBSpecs.Services.UART_SERVICE,
                        MBSpecs.Services.ACCEL_SERVICE,
                        MBSpecs.Services.DEVICE_INFO_SERVICE,
                        MBSpecs.Services.LED_SERVICE,
                        MBSpecs.Services.IO_SERVICE,
                        MBSpecs.Services.BUTTON_SERVICE
                    ]
                })
                    .then((value) => {
                    document.dispatchEvent(MicrobitBluetoothEvents.microbitBluetoothDeviceFound);
                    resolve(value);
                });
            }
            catch (e) {
                if (onRequestFailed) {
                    onRequestFailed(e);
                }
                reject(e);
            }
        });
    }
    /**
     * Attempts to connect to the micro:bit device.
     *
     * @param {BluetoothDevice} microbitDevice
     *      The microbit device to connect to.
     * @param {BluetoothRemoteGATTServer => void} onConnect
     *      Fired when a successful connection is made.
     * @param {boolean => void} onDisconnect
     *      Fired when the device disconnects.
     * @param {Error => void} onConnectFailed
     *      Called when the connection failed.
     */
    static async createEventEmittingConnection(microbitDevice, onConnect, onDisconnect, onConnectFailed) {
        try {
            const gattServer = await microbitDevice.gatt.connect();
            const microbitVersion = await MBSpecs.Utility.getModelNumber(gattServer);
            // Create the connection object.
            const connection = new EventEmittingMicrobitBluetooth(gattServer, microbitVersion, onDisconnect);
            // fire the connect event and return the connection object
            if (onConnect) {
                if (gattServer.connected) {
                    await onConnect(gattServer);
                }
            }
            return connection;
        }
        catch (e) {
            if (onConnectFailed) {
                await onConnectFailed(e);
            }
            return undefined;
        }
    }
}
export default EventEmittingMicrobitBluetooth;
