import { writable, get } from 'svelte/store';
// Singleton Store
const modalStore = writable(undefined);
const modalState = writable({
    blockClosing: false
});
function callOnCloseFunction() {
    const store = get(modalStore);
    if (store !== undefined && store.onClose) {
        store.onClose();
    }
}
/**
 * modalManager manages the store from which the modal listens to
 * It allows for opening and closing modals, as well as blocking
 * Current modals from closing.
 */
const modalManager = {
    closeModal() {
        if (get(modalState).blockClosing)
            return;
        callOnCloseFunction();
        modalStore.set(undefined);
    },
    openModal(modal) {
        if (get(modalState).blockClosing)
            return;
        callOnCloseFunction();
        modalStore.set(modal);
    },
    getModal() {
        return get(modalStore);
    },
    getModalStore() {
        return modalStore;
    },
    updateModalState(newState) {
        modalState.update(currentState => {
            return Object.assign(Object.assign({}, currentState), newState);
        });
    }
};
export default modalManager;
