<script lang="ts">
	import StdButton from "../../../stdbutton/StdButton.svelte";
	import { t } from "../../../../i18n";
	import { fade } from "svelte/transition";

	export let versionClicked: (version: number) => void;
	export let backToMainPrompt: () => void;
	export let flashFailed: boolean;


	let fade1 = true;
	let fade2 = true;

	let transferText = flashFailed ? $t("popup.connectMB.USBCompatibility.flashFail.transferText") : $t("popup.connectMB.USBCompatibility.versionStep.transferManually");

</script>

<div>
	<div>
		<div class="mb-3">
			<p>{transferText}</p>
			<p>
				{$t("popup.connectMB.USBCompatibility.versionStep.return.p1")}
				<em class="text-blue-500 cursor-pointer" on:click={backToMainPrompt}>
					{$t("popup.connectMB.USBCompatibility.versionStep.return.p2")}
				</em>
				{$t("popup.connectMB.USBCompatibility.versionStep.return.p3")}</p>
		</div>
		<p>{$t("popup.connectMB.USBCompatibility.versionStep.microbitVersion")}</p>
		<p>{$t("popup.connectMB.USBCompatibility.versionStep.microbitVersion.advice")}</p>
	</div>
	<div class="flex justify-between">
		<div class="flex-col"></div>
		<div class="flex-col min-w-20">
			<div class="{fade1 ? 'animate-pulse bg-gray-200 rounded' :''}" style="width: 200px; height: 161px"
					 transition:fade>
				<img
					alt="microbit version 1"
					on:load={() => fade1 = false}
					src="microbitV1.svg"
					width="200px"
				/>
			</div>
			<div class="w-30 m-auto">
				<StdButton onClicked={() => versionClicked(1)} pingable>Version 1</StdButton>
			</div>
		</div>
		<div class="flex-col min-w-26">
			<div class="{fade2 ? 'animate-pulse bg-gray-200 rounded' :''}" style="width: 200px; height: 161px"
					 transition:fade>
				<img
					alt="microbit version 2"
					on:load={() => fade2 = false}
					src="microbitV2.svg"
					width="200px"
				/>
			</div>
			<div class="w-30 m-auto">
				<StdButton onClicked={() => versionClicked(2)} pingable>Version 2</StdButton>
			</div>
		</div>
		<div class="flex-col"></div>
	</div>
</div>