<script lang="ts">

	export let onSetElement: (
		element: number,
		state: boolean,
		matrix: boolean[]
	) => void = undefined;
	export let onClickEnter: (element: number) => void = undefined;
	export let onHoverEnter: (element: number, event: MouseEvent) => void =
		undefined;

	export let matrix: boolean[] = [];
	let highlighted: boolean[] = [];

	if (highlighted.length != 25) {
		for (let i = 0; i < 25; i++) {
			highlighted.push(false);
		}
	}

	let startState: boolean = undefined;

	/** If bad matrix given to component => reset */
	if (typeof matrix !== "object" || matrix.length !== 25) {
		matrix = [];
		for (let i: number = 0; i < 25; i++) {
			matrix[i] = false;
		}
	}

	const clickEnter = (i: number): void => {
		startState = true;
		setElement(i, true);
		if (onClickEnter) {
			onClickEnter(i);
		}
	};

	const setElement = (i: number, state: boolean): void => {
		matrix[i] = state;
		const effectedSquares = getColumnOf(i);

		effectedSquares.forEach((value) => {
			if (value.position <= 0) {
				matrix[value.index] = state;
			} else {
				matrix[value.index] = false;
			}
		});

		if (onSetElement) {
			onSetElement(i, state, matrix);
		}
	};


	type PairingSquare = {
		index: number,
		position: number
	}

	const getColumnOf = (inx: number): PairingSquare[] => {
		const result = [];
		for (let j = inx % 5; j < 25; j += 5) {
			result.push({ index: j, position: Math.sign(inx - j) });
		}
		return result;
	};

	const clickExit = (i: number): void => {
	};

	const mouseLeftDrawingArea = () => {
		for (let j = 0; j < highlighted.length; j++) {
			highlighted[j] = false;
		}
	};

	const elementHover = (i: number, mouseEvent: MouseEvent = undefined) => {
		if (onHoverEnter) {
			onHoverEnter(i, mouseEvent);
		}

		const effectedColumns = getColumnOf(i);
		for (let j = 0; j < highlighted.length; j++) {
			highlighted[j] = false;
		}
		effectedColumns.forEach((value) => {
			highlighted[value.index] = value.position <= 0;
		});

		if (mouseEvent) {
			if (mouseEvent.buttons !== 1) {
				clickExit(i);
				return;
			}
		}
		setElement(i, startState);
	};
</script>

<!-- PATTERN MATRIX -->
<main class="buttonGrid select-none" on:mouseleave={mouseLeftDrawingArea}>
	<!-- Draw all 25 boxes -->
	{#each matrix as button, i}
		<div
			class="{button ? 'bg-blue-400 border-blue-400' : 'bg-gray-300 border-gray-300'} border-3 rounded transition ease"
			class:turnedOn={button}
			class:turnedOff={!button}
			class:border-blue-600={highlighted[i]}
			on:mousedown={() => {
        clickEnter(i);
      }}
			on:mouseenter={(e) => {
        elementHover(i, e);
      }}
		/>
	{/each}
</main>

<style>
    .buttonGrid {
        display: grid;
        grid-template-columns: repeat(5, 19%);
        grid-template-rows: repeat(5, 19%);
        gap: 2px 2px;
        grid-template-areas:
      ". . . . ."
      ". . . . ."
      ". . . . ."
      ". . . . ."
      ". . . . .";
        height: 150px;
        width: 150px;
    }

    .turnedOn {
        animation: turnOn 0.3s ease;
    }

    .turnedOff {
        animation: turnOff 0.3s ease;
    }

    @keyframes turnOn {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.15);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes turnOff {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
