/**
 * References to the Bluetooth Profile UUIDs.
 */
var MBSpecs;
(function (MBSpecs) {
    /**
     * The UUIDs of the services available on the micro:bit.
     */
    let Services;
    (function (Services) {
        /**
         * The UUID of the micro:bit's UART service.
         */
        Services.UART_SERVICE = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
        /**
         * The micro:bits accelerometer service.
         */
        Services.ACCEL_SERVICE = "e95d0753-251d-470a-a062-fa1922dfa9a8";
        /**
         * The device information service. Exposes information about manufacturer, vendor, and firmware version.
         */
        Services.DEVICE_INFO_SERVICE = "0000180a-0000-1000-8000-00805f9b34fb";
        /**
         * Used for controlling the LEDs on the micro:bit.
         */
        Services.LED_SERVICE = "e95dd91d-251d-470a-a062-fa1922dfa9a8";
        /**
         * The UUID of the micro:bit's IO service.
         */
        Services.IO_SERVICE = "e95d127b-251d-470a-a062-fa1922dfa9a8";
        /**
         * Service for buttons on the micro:bit.
         */
        Services.BUTTON_SERVICE = "e95d9882-251d-470a-a062-fa1922dfa9a8";
    })(Services = MBSpecs.Services || (MBSpecs.Services = {}));
    /**
     * The UUIDs of the characteristics available on the micro:bit.
     */
    let Characteristics;
    (function (Characteristics) {
        /**
         * Characteristic for the A button.
         */
        Characteristics.BUTTON_A = "e95dda90-251d-470a-a062-fa1922dfa9a8";
        /**
         * Characteristic for the B button.
         */
        Characteristics.BUTTON_B = "e95dda91-251d-470a-a062-fa1922dfa9a8";
        /**
         * The accelerometer data characteristic.
         */
        Characteristics.ACCEL_DATA = "e95dca4b-251d-470a-a062-fa1922dfa9a8";
        /**
         * IO data characteristic. Used for controlling IO pins on the micro:bit.
         */
        Characteristics.IO_DATA = "e95d8d00-251d-470a-a062-fa1922dfa9a8";
        /**
         * Allows the state of any|all LEDs in the 5x5 grid to be set to on or off with a single GATT operation.
         *
         * Octet 0, LED Row 1: bit4 bit3 bit2 bit1 bit0
         *
         * Octet 1, LED Row 2: bit4 bit3 bit2 bit1 bit0
         *
         * Octet 2, LED Row 3: bit4 bit3 bit2 bit1 bit0
         *
         * Octet 3, LED Row 4: bit4 bit3 bit2 bit1 bit0
         *
         * Octet 4, LED Row 5: bit4 bit3 bit2 bit1 bit0
         */
        Characteristics.LED_MATRIX_STATE = "e95d7b77-251d-470a-a062-fa1922dfa9a8";
        /**
         * The model number of the micro:bit as a string.
         */
        Characteristics.MODEL_NUMBER = "00002a24-0000-1000-8000-00805f9b34fb";
        /**
         * The UUID of the micro:bit's UART TX characteristic.
         * Used to listen for data from the micro:bit.
         */
        Characteristics.UART_DATA_TX = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
        /**
         * The UUID of the micro:bit's UART RX characteristic.
         * Used for sending data to the micro:bit.
         */
        Characteristics.UART_DATA_RX = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";
    })(Characteristics = MBSpecs.Characteristics || (MBSpecs.Characteristics = {}));
    let USBSpecs;
    (function (USBSpecs) {
        USBSpecs.PRODUCT_ID = 516;
        USBSpecs.VENDOR_ID = 3368;
        USBSpecs.FICR = 0x10000000;
        USBSpecs.DEVICE_ID_1 = 0x064;
        USBSpecs.MICROBIT_NAME_LENGTH = 5;
        USBSpecs.MICROBIT_NAME_CODE_LETTERS = 5;
    })(USBSpecs = MBSpecs.USBSpecs || (MBSpecs.USBSpecs = {}));
    /**
     * The state of the buttons on the micro:bit. Becomes LongPressed when the button is held for more than ~2 second.
     */
    let ButtonStates;
    (function (ButtonStates) {
        ButtonStates[ButtonStates["Released"] = 0] = "Released";
        ButtonStates[ButtonStates["Pressed"] = 1] = "Pressed";
        ButtonStates[ButtonStates["LongPressed"] = 2] = "LongPressed";
    })(ButtonStates = MBSpecs.ButtonStates || (MBSpecs.ButtonStates = {}));
    /**
     * Utilities for working with the micro:bit's Bluetooth Profile.
     */
    class Utility {
        /**
         * Fetches the model number of the micro:bit.
         * @param {BluetoothRemoteGATTServer} gattServer The GATT server to read from.
         * @return {Promise<number>} The model number of the micro:bit. 1 for the original, 2 for the new.
         */
        static async getModelNumber(gattServer) {
            const deviceInfo = await gattServer.getPrimaryService(Services.DEVICE_INFO_SERVICE);
            const modelNumber = await deviceInfo.getCharacteristic(Characteristics.MODEL_NUMBER);
            // Read the value and convert it to UTF-8 (as specified in the Bluetooth specification).
            const modelNumberValue = await modelNumber.readValue();
            const decodedModelNumber = new TextDecoder().decode(modelNumberValue);
            // The model number either reads "BBC micro:bit" or "BBC micro:bit V2.0". Still unsure if those are the only cases.
            if (decodedModelNumber.toLowerCase() === "BBC micro:bit".toLowerCase()) {
                return 1;
            }
            if (decodedModelNumber.toLowerCase().includes("BBC micro:bit v2".toLowerCase())) {
                return 2;
            }
            // Something went wrong
            throw new Error("Could not read model number");
        }
        /**
         * Converts a micro:bit serial number to it's corresponding friendly name
         * @param {number} serialNo The serial number of the micro:bit
         * @returns {string} the name of the micro:bit.
         */
        static serialNumberToName(serialNo) {
            let d = USBSpecs.MICROBIT_NAME_CODE_LETTERS;
            let ld = 1;
            let name = "";
            for (let i = 0; i < USBSpecs.MICROBIT_NAME_LENGTH; i++) {
                const h = Math.floor((serialNo % d) / ld);
                serialNo -= h;
                d *= USBSpecs.MICROBIT_NAME_CODE_LETTERS;
                ld *= USBSpecs.MICROBIT_NAME_CODE_LETTERS;
                name = Utility.CODEBOOK[i][h] + name;
            }
            return name;
        }
        /**
         * Converts a pairing pattern to a name.
         * @param {boolean[]} pattern The pattern to convert.
         * @returns {string} The name of the micro:bit.
         */
        static patternToName(pattern) {
            const charIndices = [];
            const verticals = [-1, -1, -1, -1, -1];
            for (let i = 0; i < 5; i++) {
                for (let j = 0; j < 5; j++) {
                    const x = j;
                    const y = i;
                    if (pattern[x + y * 5]) {
                        if (verticals[x] < 4 - y) {
                            verticals[x] = 4 - y;
                        }
                    }
                }
            }
            verticals.forEach((v, i) => {
                if (v === -1) {
                    return;
                }
                else {
                    charIndices.push(v * 5 + i);
                }
            });
            const code = [];
            charIndices.forEach((charIndex) => {
                const x = charIndex % 5;
                const y = Math.floor(charIndex / 5);
                code.push(this.CODEBOOK[x][y]);
            });
            return code.join("");
        }
        /**
         * Converts a binary number represented as an array of numbers into an octet.
         *
         * @param array Bitmap array to convert.
         * @returns {number} The octet.
         */
        static arrayToOctet(array) {
            let numArray = array;
            if (typeof array[0] === "boolean")
                numArray = array.map((value) => value ? 1 : 0);
            let sum = 0;
            // We track the bit pos because the value of the octet, will just be the decimal value of the bit representation.
            let bitPos = 0;
            // Walk in reverse order to match the order of the micro:bit.
            for (let i = numArray.length - 1; i >= 0; i--) {
                // Just calculate the decimal value of the bit position and add it to the sum.
                sum += numArray[i] * Math.pow(2, bitPos);
                bitPos++;
            }
            return sum;
        }
    }
    Utility.CODEBOOK = [
        ["z", "v", "g", "p", "t"],
        ["u", "o", "i", "e", "a"],
        ["z", "v", "g", "p", "t"],
        ["u", "o", "i", "e", "a"],
        ["z", "v", "g", "p", "t"]
    ];
    MBSpecs.Utility = Utility;
})(MBSpecs || (MBSpecs = {}));
export default MBSpecs;
