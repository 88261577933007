<script>
  import { livedata, settings, state } from "../../script/common";
  import { onMount } from "svelte";
  import { SmoothieChart, TimeSeries } from "../../script/smoothie";
  import { get } from "svelte/store";

  var canvas = undefined;
	var chart = undefined;
	let lineX = new TimeSeries();
	let lineY = new TimeSeries();
	let lineZ = new TimeSeries();
	let recordLines = new TimeSeries();
	const lineWidth = 2;

	// On mount draw smoothieChart
	onMount(() => {
		chart = new SmoothieChart({
			maxValue: 2.3,
			minValue: -2,
			millisPerPixel: 7,
			grid: {
				fillStyle: "#ffffff00",
				strokeStyle: "rgba(48,48,48,0.20)",
				millisPerLine: 3000,
				borderVisible: false
			},
			interpolation: "linear"
		});

		chart.addTimeSeries(lineX, { lineWidth, strokeStyle: "#f9808e" });
		chart.addTimeSeries(lineY, { lineWidth, strokeStyle: "#80f98e" });
		chart.addTimeSeries(lineZ, { lineWidth, strokeStyle: "#808ef9" });
		chart.addTimeSeries(recordLines, {
			lineWidth: 3,
			strokeStyle: "#4040ff44",
			fillStyle: "#0000ff07"
		});
		chart.streamTo(canvas, 0);
		chart.stop();
	});

	// Start and stop chart when microbit connect/disconnect
	$: {
		if (chart) {
			$state.isConnected ? chart.start() : chart.stop();
		}
	}

	// Draw on graph to display that users are recording
	$: recordingStarted($state.isRecording || $state.isTesting);

	// Function to clearly diplay the area in which users are recording
	function recordingStarted(isRecording) {
		if (!isRecording) return;

		// Set start line
		recordLines.append(new Date().getTime() - 1, -2);
		recordLines.append(new Date().getTime(), 2.3);

		// Wait a second and set end line
		setTimeout(() => {
			recordLines.append(new Date().getTime() - 1, 2.3);
			recordLines.append(new Date().getTime(), -2);
		}, get(settings).duration);
	}

	// When state changes, update the state of the canvas
	$: updateCanvas($state);

	let unsubscribeFromData = undefined;
	let i = 0;

	// If state is connected. Start updating the graph whenever there is new data
	// From the Micro:Bit
	function updateCanvas(currentState) {
		if (currentState.isConnected) {
			unsubscribeFromData = livedata.subscribe((data) => {
				if (i % 4 === 0) {
					const t = new Date().getTime();
					lineX.append(t, data.smoothedAccelX);
					lineY.append(t, data.smoothedAccelY);
					lineZ.append(t, data.smoothedAccelZ);
				}
			});

			// Else if we're currently subscribed to data. Unsubscribe.
			// This means that the micro:bit has been disconnected
		} else if (unsubscribeFromData) {
			unsubscribeFromData();
			unsubscribeFromData = undefined;
		}
	}

	// Updates width to ensure that the canvas fills the whole screen
	export let width;

</script>

<main>
	<canvas bind:this={canvas} height="160" id="smoothie-chart" {width} />
</main>
